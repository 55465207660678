/*
 * Flood Light START
 * Sample: onclick="callFloodlight('view-warranty-booklet');"
 */
function callFloodlight(tagId, ModelName, ModelYear) {

  // var axel = Math.random() + "";
  // var a = axel * 10000000000000;

  // switch (tagId) {

  //   // Program & Benefits: Fire when user clicks "view warranty booklet"
  //   case "view-warranty-booklet":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endtw0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endtw0+standard'
  //     });
  //     break;

  //   //Dealer Locator: Fire once a user submits zip code
  //   case "dealer-zip-submit":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endtd0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endtd0+standard'
  //     });
  //     break;

  //   //Vehicle Compare:Fire once a user hits "ready to compare" button
  //   case "ready-to-compare":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmv0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmv0+standard'
  //     });
  //     break;

  //   //Financial Calculator: Fire once a user clicks "Find vehicles in my budget" button
  //   case "find-vehicles-in-my-budget":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endtf0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'session_id': floodLightSessionManagerValue(),
  //       'send_to': 'DC-4114413/hrmhcpv/endtf0+per_session'
  //     });
  //     break;

  //   //Alert Created: Fire once someone submit/clicks "create alert"
  //   case "create-alert":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endta0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endta0+standard'
  //     });
  //     break;

  //   //Dealer Email: - Fire once a user hits "send" on contact dealer/email
  //   case "contact-dealer":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmd0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmd0+standard'
  //     });
  //     break;

  //   //Dealer Chat: Fire once a user hits "chat" button
  //   case "dealer-chat":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmd00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmd00+standard'
  //     });
  //     break;

  //     // Removed : HCPVISSUES-754
  //   //Dealer Call:Fire once a user hits "call" button
  //   // case "dealer-call":
  //   //   // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmd000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //   //   gtag('event', 'conversion', {
  //   //     'allow_custom_scripts': true,
  //   //     'send_to': 'DC-4114413/hrmhcpv/endmd000+standard'
  //   //   });
  //   //   break;

  //   //Customer Reviews: Fire when a shopper clicks "read more" or Edmunds logo 
  //   case "customer-reviews":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmc0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmc0+standard'
  //     });
  //     break;

  //   //Inspection Report:Fire when a shopper clicks "View free carfax report"
  //   case "inspection-report":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmi00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmi00+standard'
  //     });
  //     break;

  //   //Inventory Search Results:"Fire once user has searched and been provided listings for a model 
  //   //ModelName is required
  //   //ModelYear is required
  //   //**REMOVED  HCPVISSUES-682*/
  //   case "inventory-search-results":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endti00;u1=[' + ModelName + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     // gtag('event', 'conversion', {
  //     //   'allow_custom_scripts': true,
  //     //   'u1': ModelName,
  //     //   'u2': ModelYear,
  //     //   'send_to': 'DC-4114413/hrmhcpv/endti00+standard'
  //     // });

  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'session_id': floodLightSessionManagerValue(),
  //       'u1': ModelName,
  //       'u2': ModelYear,
  //       'send_to': 'DC-4114413/hrmhcpv/endti00+per_session'
  //     });

  //     break;

  //   //Inventory Search Results:"Fire once user has searched and been provided listings for all models
  //   case "inventory-search-results-allmodels":
  //     document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endti00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     break;

  //   //Inventory Search Details:Fire once a user clicks on "see details", would like to fire on the page load but if unavailable would settle for click on "see details button"
  //   //ModelName is required
  //   //ModelYear is required
  //   //***REMOVED HCPVISSUES-682 */
  //   case "inventory-search-details": 
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmi0;u1=[' + ModelName + '];u2=[' + ModelYear + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'u1': ModelName,
  //       'u2': ModelYear,
  //       'send_to': 'DC-4114413/hrmhcpv/endmi0+standard'
  //     });
  //     break;

  //   // VDP: Add to contact a dealer under vehicle details
  //   case "vdp-contact-dealer":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmd00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114413/hrmhcpv/endmd00+standard'
  //     });
  //     break;
  // }


}
/*
 * FloodLight END
 * 
 */

// Firefox 'Tracking Protection' prevents the satelliteLib from loading.
// Extend this object with any function you would normally call on _satellite.
if (!_satellite) {
  console.log("AdobeDTM satelliteLib tracker failed to load. You are probably running FireFox with 'Tracking Protection' enabled. Subsequent '_satellite' calls will be no-ops.");
  var _satellite = {
    setVar: () => { },
    track: () => { },
    pageBottom: () => { },
  }
}

/**
 * 
 *  Adobe Launch START
 */

  //get the current page name
  // var sPath, sPageName;
  // sPath = window.location.pathname;
  // sPageName = sPath.substring(sPath.lastIndexOf('/') + 1);
  // if (sPageName == ""){
  //   sPageName = "MAIN"
  // }
  // var _props = {
  //   'url' : 'https://www.hondacertified.com/',
  //   'sitelanguage' : 'ENGLISH',
  //   'pagepath' : window.location.href,
  //   's.prop47' : 'ENGLISH',
  //   'pageName' : sPageName
  // };

/**
 * 
 *  Adobe Launch END
 */
$(document).ready(function () {

  /*
   * DTM: digitalDataLayer Tracking  start
   */



  // temp. data layer for: dtmDataLayerGlobal
  // var digitalDataLayer = {
  //   sitelanguage: "english",
  //   zipstatus: "", // check local storage
  //   pageName: sPageName
  // }

  // //zipstatus: is there a zip?
  // var checkCurrentZipCode = localStorageGetItemCommon("_GeoCurrentZipCode");

  // if (checkCurrentZipCode == null) {
  //   // zip is NOT available
  //   digitalDataLayer_update('zipstatus', 'no');
  // } else {
  //   // zip is available
  //   digitalDataLayer_update('zipstatus', 'yes');
  // }

  // // update dtmDataLayerGlobal with latest value
  // function digitalDataLayer_update(category, result) {
  //   if (typeof digitalDataLayer !== 'undefined') {

  //     //update category and results
  //     digitalDataLayer[category] = result;
  //     var dtmDataLayerGlobal = JSON.stringify(digitalDataLayer);

  //     //append to the page
  //     var scrpt = document.createElement("script");
  //     scrpt.type = "text/javascript";
  //     scrpt.innerHTML = 'dtmDataLayerGlobal = ' + dtmDataLayerGlobal;
  //     // console.log(scrpt);
  //     document.getElementById("dtm-data-layer").appendChild(scrpt);

  //     debug && console.log(dtmDataLayerGlobal);
  //   }


  // }

  /*
   * DTM: digitalDataLayer Tracking END
   */


  /*
   * Oracle Pixel Tags Start
   */



  // Function to check query string for a value
  // function getQueryStringValue(variable) {
  //   var query = window.location.search.substring(1).toLowerCase();
  //   var vars = query.split("&");
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     if (pair[0] == variable) {
  //       return pair[1];
  //     }
  //   }
  //   return (false);
  // }

  // get the value for model group name
  //lowercase value only
  // var _trackingPathname = getQueryStringValue('modelgroupname');


  // URLs that need Oracle tag + home page
  // lower case only
  // var _trackingOracleURL = [
  //   'accord%20sedan',
  //   'pilot',
  //   'cr-v',
  //   'civic%20sedan',
  //   'odyssey',
  //   'hr-v',
  //   'fit',
  //   'ridgeline',
  //   'civic%20Si%20sedan',
  //   'accord%2coupe',
  //   'crosstour',
  //   'civic%20coupe',
  //   'insight',
  //   'accord%20hybrid',
  //   'cr-z',
  //   'civic%20hybrid',
  //   'civic%20si%20coupe',
  //   'cr-v&year=2015'
  // ];



  // // check _trackingPathname matches anything from _trackingOracleURL
  // if ($.inArray(_trackingPathname, _trackingOracleURL) != -1 || window.location.pathname == '/') {

  //   // if URL is matching, Fire the tag
  //   getOracleTags();

  // };


  // function getOracleTags() {
  //   var pixelUrl = 'https://h.nexac.com/e/mp/g-5291.xgi?pkey=5291';
  //   var query = getParentUrl();

  //   if (query != null && query.length > 0) {
  //     var params = query.split("&");
  //     var gclid = null;
  //     var gclsrc = null;
  //     for (var i = 0; i < params.length; i++) {
  //       var pos = params[i].indexOf("=");
  //       var name = params[i].substring(0, pos);
  //       if (name == "gclid") {
  //         gclid = params[i].substring(pos + 1);
  //       } else if (name == "gclsrc") {
  //         gclsrc = params[i].substring(pos + 1);
  //       }
  //     }
  //     createPixel(pixelUrl, gclid, gclsrc);
  //   }
  // }

  // function getParentUrl() {
  //   var isInIframe = (parent !== window),
  //     query = null;

  //   if (isInIframe) {
  //     if (document.referrer) {
  //       query = document.referrer.split('?')[1];
  //     }
  //   } else {
  //     query = document.location.search.substr(1);
  //   }
  //   return query;
  // }

  // function createPixel(pixelUrl, gclid, gclsrc) {

  //   if (gclid != null || gclsrc != null) {
  //     var img = document.createElement("img");
  //     var url = pixelUrl;
  //     if (gclid != null) {
  //       url = url + "&chpth=" + gclid;
  //     }

  //     if (gclsrc != null) {
  //       url = url + "&chpck=" + gclsrc;
  //     }

  //     img.width = '1';
  //     img.height = '1';
  //     img.border = '0';
  //     img.src = url;
  //     var na_tag = document.getElementById("na_pixel");
  //     na_tag.appendChild(img);

  //   }
  // }
  /*
   * Oracle Pixel Tags End
   */


  /*
 * Tracking - CampaignName start
 */
  // Function to check query string for a value - NOT LOWERCASE
  // function getQueryStringValueAny(variable) {
  //   var query = window.location.search.substring(1);
  //   var vars = query.split("&");
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     if (pair[0].toLowerCase() === variable.toLowerCase()) {
  //       return pair[1];
  //     }
  //   }
  //   return (false);
  // }
  // // get the value for CID
  // //lowercase value only
  // var _CampaignName = getQueryStringValueAny('cid');

  // if (_CampaignName) {

  //   var inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
  //   //set the cookie with the CID value
  //   Cookies.set('_CampaignName', _CampaignName);

  // }

  /*
   * Tracking - CampaignName end
   */

  // document.ready END
});

/*
 * floodlight session manager start
 */

function floodLightSessionManager(catId) {

  // var floodlightSessionValue = Cookies.get('floodlightSessionValue');
  // var randomValue = randomValueGenerator(16, '0123456789abcdefghijklmnopqrstuvwxyz');
  // if (floodlightSessionValue) {
  //   //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=' + catId + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + floodlightSessionValue + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //   gtag('event', 'conversion', {
  //     'allow_custom_scripts': true,
  //     'session_id': floodlightSessionValue,
  //     'send_to': 'DC-4114413/hrmhcpv/' + catId + '+per_session'
  //   });
  // } else {
  //   Cookies.set('floodlightSessionValue', randomValue, {
  //     //expire in 30 minutes
  //     expires: 1 / 48
  //   });
  //   // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=' + catId + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + randomValue + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //   gtag('event', 'conversion', {
  //     'allow_custom_scripts': true,
  //     'session_id': randomValue,
  //     'send_to': 'DC-4114413/hrmhcpv/' + catId + '+per_session'
  //   });
  // }
};

//This funtion will return the session value
// function floodLightSessionManagerValue() {

//   var floodlightSessionValue = Cookies.get('floodlightSessionValue');
//   var randomValue = randomValueGenerator(16, '0123456789abcdefghijklmnopqrstuvwxyz');
//   if (floodlightSessionValue) {
//     return floodlightSessionValue;
//   } else {
//     Cookies.set('floodlightSessionValue', randomValue, {
//       //expire in 30 minutes
//       expires: 1 / 48
//     });
//     return randomValue;

//   }
// }; 

// function randomValueGenerator(length, chars) {
//   var result = '';
//   for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
//   return result;
// }

//When user clicks away from the page, remove the cookie "floodlightSessionValue"
window.addEventListener('beforeunload', function (e) {
  // Cancel the event as stated by the standard.
  // Remove the cookie
  // Cookies.remove('floodlightSessionValue'); 
  
  //e.returnValue "remove Cookie"

});

  /*
   * floodlight session manager end
   */


